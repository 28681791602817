import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  SET_USER_LIST,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_ALERT_LIST,
  ADD_NEW_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
  THEME,
  ADD_NEW_COURSE,
  SET_COURSE_LIST,
  UPDATE_COURSE,
  DELETE_COURSE,
  OPEN_COURSE_FORM,
  CLOSE_COURSE_FORM,
  EDIT_SELECTED_COURSE,
  COURSE_FORM_TOOGLE_LOADING,
  ADD_NEW_BOOKING,
  SET_BOOKING_LIST,
  UPDATE_BOOKING,
  DELETE_BOOKING,
  OPEN_BOOKING_FORM,
  CLOSE_BOOKING_FORM,
  EDIT_SELECTED_BOOKING,
  BOOKING_FORM_TOOGLE_LOADING,
  ADD_NEW_CLASSROOM,
  SET_CLASSROOM_LIST,
  UPDATE_CLASSROOM,
  DELETE_CLASSROOM,
  OPEN_CLASSROOM_FORM,
  CLOSE_CLASSROOM_FORM,
  EDIT_SELECTED_CLASSROOM,
  CLASSROOM_FORM_TOOGLE_LOADING,
  ADD_NEW_RESOURCE,
  SET_RESOURCE_LIST,
  UPDATE_RESOURCE,
  DELETE_RESOURCE,
  OPEN_RESOURCE_FORM,
  CLOSE_RESOURCE_FORM,
  EDIT_SELECTED_RESOURCE,
  RESOURCE_FORM_TOOGLE_LOADING,
  ADD_NEW_SUBJECT,
  SET_SUBJECT_LIST,
  UPDATE_SUBJECT,
  DELETE_SUBJECT,
  OPEN_SUBJECT_FORM,
  CLOSE_SUBJECT_FORM,
  EDIT_SELECTED_SUBJECT,
  SUBJECT_FORM_TOOGLE_LOADING,
  ADD_NEW_BOOKTYPE,
  SET_BOOKTYPE_LIST,
  UPDATE_BOOKTYPE,
  DELETE_BOOKTYPE,
  OPEN_BOOKTYPE_FORM,
  CLOSE_BOOKTYPE_FORM,
  EDIT_SELECTED_BOOKTYPE,
  BOOKTYPE_FORM_TOOGLE_LOADING,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  true
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SUBJECTS
export const subjects = handleActions(
  {
    [SET_SUBJECT_LIST]: (state, action) => action.payload,
    [ADD_NEW_SUBJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SUBJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SUBJECT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const subjectsForm = handleActions(
  {
    [OPEN_SUBJECT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SUBJECT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SUBJECT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SUBJECT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// COURSES
export const courses = handleActions(
  {
    [SET_COURSE_LIST]: (state, action) => action.payload,
    [ADD_NEW_COURSE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_COURSE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_COURSE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const coursesForm = handleActions(
  {
    [OPEN_COURSE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_COURSE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_COURSE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [COURSE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);
// BOOKINGS
export const bookings = handleActions(
  {
    [SET_BOOKING_LIST]: (state, action) => action.payload,
    [ADD_NEW_BOOKING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_BOOKING]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_BOOKING]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const bookingsForm = handleActions(
  {
    [OPEN_BOOKING_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_BOOKING_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_BOOKING]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [BOOKING_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CLASSROOMS
export const classrooms = handleActions(
  {
    [SET_CLASSROOM_LIST]: (state, action) => action.payload,
    [ADD_NEW_CLASSROOM]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CLASSROOM]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CLASSROOM]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const classroomsForm = handleActions(
  {
    [OPEN_CLASSROOM_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CLASSROOM_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CLASSROOM]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CLASSROOM_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// RESOURCES
export const resources = handleActions(
  {
    [SET_RESOURCE_LIST]: (state, action) => action.payload,
    [ADD_NEW_RESOURCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_RESOURCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_RESOURCE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const resourcesForm = handleActions(
  {
    [OPEN_RESOURCE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_RESOURCE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_RESOURCE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [RESOURCE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// BOOKTYPES
export const booktypes = handleActions(
    {
        [SET_BOOKTYPE_LIST]: (state, action) => action.payload,
        [ADD_NEW_BOOKTYPE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_BOOKTYPE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_BOOKTYPE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const booktypesForm = handleActions(
    {
        [OPEN_BOOKTYPE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_BOOKTYPE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_BOOKTYPE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [BOOKTYPE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);
