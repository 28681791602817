import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { ROLES_RAW } from "../../constants/permissions";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentTheme = createSelector(
  (state) => state.currentTheme,
  (currentTheme) => currentTheme
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  !profile ? null : (ROLES_RAW.includes(profile.role) ? profile.role : null)
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* USERS */
export const getUsers = createSelector(
  (state) => state.users,
  (users) => users
);
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* ALERT */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* COURSES */
export const getCourses = createSelector(
  (state) => state.courses,
  (courses) => courses
);
export const getCoursesForm = createSelector(
  (state) => state.coursesForm,
  (form) => form
);

/* BOOKINGS */
export const getBookings = createSelector(
  (state) => state.bookings,
  (bookings) => bookings
);
export const getBookingsForm = createSelector(
  (state) => state.bookingsForm,
  (form) => form
);

/* CLASSROOMS */
export const getClassrooms = createSelector(
  (state) => state.classrooms,
  (classrooms) => classrooms
);
export const getClassroomsForm = createSelector(
  (state) => state.classroomsForm,
  (form) => form
);

/* RESOURCES */
export const getResources = createSelector(
  (state) => state.resources,
  (resources) => resources
);
export const getResourcesForm = createSelector(
  (state) => state.resourcesForm,
  (form) => form
);

/* SUBJECTS */
export const getSubjects = createSelector(
  (state) => state.subjects,
  (subjects) => subjects
);
export const getSubjectsForm = createSelector(
  (state) => state.subjectsForm,
  (form) => form
);

/* BOOKTYPES */
export const getBooktypes = createSelector(
    (state) => state.booktypes,
    (booktypes) => booktypes
);
export const getBooktypesForm = createSelector(
    (state) => state.booktypesForm,
    (form) => form
);
