import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  courses,
  coursesForm,
  bookings,
  bookingsForm,
  classrooms,
  classroomsForm,
  resources,
  resourcesForm,
  subjects,
  subjectsForm,
  booktypes,
  booktypesForm,
} from "./Reducers";

export default combineReducers({
  booktypes,
  booktypesForm,
  bookings,
  bookingsForm,
  subjects,
  subjectsForm,
  courses,
  coursesForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  classrooms,
  classroomsForm,
  resources,
  resourcesForm,
  currentTheme,
});
