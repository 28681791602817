
export const API = {
	booktypes: {
    create: `/booktype`,
    edit: `/booktype/`,
    delete: `/booktype/`,
    list: `/booktype`,
  },
  bookings: {
    create: `/booking`,
    edit: `/booking/`,
    delete: `/booking/`,
    list: `/booking`,
  },
  courses: {
    create: `/course`,
    edit: `/course/`,
    delete: `/course/`,
    list: `/course`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
  classrooms: {
    create: `/classroom`,
    edit: `/classroom/`,
    delete: `/classroom/`,
    list: `/classroom`,
  },
  resources: {
    create: `/resource`,
    edit: `/resource/`,
    delete: `/resource/`,
    list: `/resource`,
  },
  subjects: {
    create: `/subject`,
    edit: `/subject/`,
    delete: `/subject/`,
    list: `/subject`,
  },
};
