import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_COURSE,
    DELETE_COURSE,
    COURSE_FORM_TOOGLE_LOADING,
    SET_COURSE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_COURSE,
} from "../constants";
import { formatCourses } from "./settingsActionsUtils";

/* COURSE LIST */
export const fetchCourses = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.courses.list)
            .then((response) => {
                const courses = formatCourses(response.data);
                dispatch({
                    type: SET_COURSE_LIST,
                    payload: keyBy(courses, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// COURSES
export const createCourse = (newCourse) => {
    return async (dispatch) => {
        dispatch({ type: COURSE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.courses.create, newCourse)
            .then((response) => {
                const course = formatCourses(response.data);
                dispatch({ type: ADD_NEW_COURSE, payload: course });
                dispatch({ type: COURSE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Curso creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COURSE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateCourse = (updatedCourse) => {
    return async (dispatch) => {
        dispatch({ type: COURSE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.courses.edit}${updatedCourse && updatedCourse._id}`, updatedCourse)
            .then((response) => {
                const course = formatCourses(response.data);
                dispatch({ type: UPDATE_COURSE, payload: course });
                dispatch({ type: COURSE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Curso actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COURSE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteCourses = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: COURSE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.courses.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_COURSE, payload: Ids });
                dispatch({ type: COURSE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Curso eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COURSE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
