import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_RESOURCE,
    DELETE_RESOURCE,
    RESOURCE_FORM_TOOGLE_LOADING,
    SET_RESOURCE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_RESOURCE,
} from "../constants";
import { formatResources } from "./settingsActionsUtils";

/* RESOURCE LIST */
export const fetchResources = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.resources.list)
            .then((response) => {
                const resources = formatResources(response.data);
                dispatch({
                    type: SET_RESOURCE_LIST,
                    payload: keyBy(resources, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// RESOURCES
export const createResource = (newResource) => {
    return async (dispatch) => {
        dispatch({ type: RESOURCE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.resources.create, newResource)
            .then((response) => {
                const resource = formatResources(response.data);
                dispatch({ type: ADD_NEW_RESOURCE, payload: resource });
                dispatch({ type: RESOURCE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Aula creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: RESOURCE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateResource = (updatedResource) => {
    return async (dispatch) => {
        dispatch({ type: RESOURCE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.resources.edit}${updatedResource && updatedResource._id}`, updatedResource)
            .then((response) => {
                const resource = formatResources(response.data);
                dispatch({ type: UPDATE_RESOURCE, payload: resource });
                dispatch({ type: RESOURCE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Aula actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: RESOURCE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteResources = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: RESOURCE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.resources.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_RESOURCE, payload: Ids });
                dispatch({ type: RESOURCE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Aula eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: RESOURCE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
